@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.container {
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing-xs;
  gap: $spacing-xs;

  &:hover {
    text-decoration: none;

    .base {
      opacity: 0;
    }

    .hover {

      img {
        transform: scale(1.1);
      }
    }
  }

  &[data-type='grid'] {

    .image {
      height: calc((50dvw - 1.55rem) / 3 * 4);

      @include screen-size('small') {
        height: calc(((100dvw - 4.15rem) / 3) / 3 * 4);
      }

      @include screen-size('medium') {
        height: calc(((100dvw - 5.15rem) / 4) / 3 * 4);
      }

      @include screen-size('large') {
        height: calc(((100dvw - 13.15rem) / 4) / 3 * 4);
      }

      @include screen-size('custom', 1260px) {
        height: calc((((100dvw - 14.15rem) / 5) / 3 * 4));
      }

      @include screen-size('custom', 1600px) {
        height: 370px;
      }
    }
  }

  &[data-type='carousel'] {

    .image {
      width: 11rem;
      height: 14.65rem;

      @include screen-size('custom', 1260px) {
        width: 16.1rem;
        height: 21.4rem;
      }
    }
  }
}

.label {
  @include text-md;
  @include text-bold;

  color: $label-text;
}

.tag {
  @include text-sm;
  @include text-normal;

  color: $label-text;
}

.image {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  background-color: $fill-primary-light;

  img {
    border-radius: 0.75rem;
    aspect-ratio: 3 / 4;
    object-fit: contain;
    transition: all 500ms ease-in-out;
  }

  .mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0.0625rem solid $fill-primary-light;
    border-radius: 0.8rem;
  }

  .base,
  .hover {
    transition: all 300ms ease-in-out;
  }
}
